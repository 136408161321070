import React from "react"
import DaZeroAlBrand from "./DaZeroAlBrand"
import GliAutori from "./GliAutori"
import GliEroi from "./GliEroi"
import Quote from "./Quote"
import Acquista from "./Acquista"
import Cover from "./Cover"

export default function Home() {
  return (
    <>
      <Cover />
      <DaZeroAlBrand />
      <GliAutori />
      <GliEroi />
      <Quote />
      <Acquista />
    </>
  )
}
