import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const HeroImage = ({ fileName, alt, style }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fixed(width: 150) {
            originalName,
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const fixed = (allImageSharp.nodes.find(n => n.fixed.originalName === fileName) || {})
    .fixed

  return (
    <Img fixed={fixed} alt={alt} style={style} />
  )
}

export default HeroImage
